import { faSparkles } from '@fortawesome/pro-duotone-svg-icons'
import { Card, CardBody, PageTitle, Tab, Tabs } from '@mainstem/react-mainstem'

import imgAIPredictedSearchQueries from './assets/img/AI Predicted Search Queries.png'
import imgAIProductMatching from './assets/img/AI Product Matching.png'
import imgAIRecommendedProducts from './assets/img/AI Recommended Products.png'
import imgAIEmailScraping from './assets/img/AI Email Scraping.png'
import imgAIWebScraping from './assets/img/AI Web Scraping.png'
import imgProductPurchasingPredictions from './assets/img/Product - Purchasing Predictions.png'
import imgRAGChatBotPublicInformation from './assets/img/RAG Chat Bot - Public Information.png'
import imgRequisitionSummaries from './assets/img/Requisition - Summaries.png'

const PageAI = () => {
  return (
    <>
      <PageTitle
        icon={faSparkles}
        title="MainStem's AI"
        subtitle="Learn all about the different ways MainStem is incorporating AI into it's platform"
      />
      <br />
      <Card>
        <CardBody topPadding>
          <Tabs vertical>
            <Tab title='SideKick - RAG'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>MainStem SideKick - RAG</h1>
              <div>
                <a href={imgRAGChatBotPublicInformation} target='_blank' rel='noreferrer'>
                  <img src={imgRAGChatBotPublicInformation} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem's AI-powered chatbot leverages Retrieval-Augmented Generation (RAG) to provide users with
                  instant, intelligent responses about various data models within the platform. Whether users have
                  questions about products, suppliers, or orders, the chatbot delivers accurate information, complete
                  with direct links to the relevant details in the platform—making data access seamless and efficient.
                </p>
                <br />
                <p>
                  This model was custom-built specifically for MainStem, incorporating both our verified network data
                  and each customer's private indexed data. This ensures users receive trusted, up-to-date insights
                  tailored to their unique business needs while maintaining strict data separation for security and
                  privacy.
                </p>
              </div>
            </Tab>
            <Tab title='AI - Web Scraping'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>AI - Web Scraping</h1>
              <div>
                <a href={imgAIWebScraping} target='_blank' rel='noreferrer'>
                  <img src={imgAIWebScraping} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered web scraping feature allows users to quickly extract product information from
                  any website by simply submitting a URL. Our AI automatically retrieves key details such as product
                  name, image, price, SKU, and more—eliminating the need for manual data entry.
                </p>
                <br />
                <p>
                  Once the data is collected, we take it a step further by cross-referencing it with our custom-built AI
                  model for product optionality. This enables users to find exact product matches and discover
                  alternative options within the MainStem ecosystem, ensuring smarter purchasing decisions with greater
                  transparency and efficiency.
                </p>
              </div>
            </Tab>
            <Tab title='AI - Email Scraping'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>AI - Web Scraping</h1>
              <div>
                <a href={imgAIEmailScraping} target='_blank' rel='noreferrer'>
                  <img src={imgAIEmailScraping} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered email scraping feature allows our platform to scan incoming email messages for
                  relevant supply chain information, like shipment tracking details.
                </p>
                <br />
                <p>
                  "shipments@mainstem.io" is the designated email address for users to send their emails to. Our AI will
                  then extract the relevant information from the email and automatically update the user's order status
                  in the platform, ensuring that users have access to the most up-to-date information about their
                  shipments without having to manually check their emails or contact suppliers.
                </p>
              </div>
            </Tab>
            <Tab title='AI - Recommended Products'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>AI - Recommended Products</h1>
              <div>
                <a href={imgAIRecommendedProducts} target='_blank' rel='noreferrer'>
                  <img src={imgAIRecommendedProducts} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered product recommendation engine delivers personalized suggestions tailored to each
                  user’s unique purchasing behavior. By analyzing past interactions, product views, and category
                  preferences, our AI identifies relevant items that align with the user's needs—streamlining
                  procurement and helping users discover new options effortlessly.
                </p>
                <br />
                <p>
                  This intelligent system continuously refines recommendations based on real-time engagement, ensuring
                  that users always receive the most relevant and strategic purchasing suggestions to optimize their
                  supply chain.
                </p>
              </div>
            </Tab>
            <Tab title='AI - Predicted Search Queries'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>AI - Predicted Search Queries</h1>
              <div>
                <a href={imgAIPredictedSearchQueries} target='_blank' rel='noreferrer'>
                  <img src={imgAIPredictedSearchQueries} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered search suggestion feature helps users quickly find the information they need by
                  intelligently recommending queries based on their past interactions, product views, and platform
                  activity. Instead of starting from scratch, users receive smart, context-aware search suggestions that
                  align with their purchasing habits and operational needs.
                </p>
                <br />
                <p>
                  By anticipating what users are likely looking for, this feature enhances efficiency, reduces search
                  time, and ensures a more intuitive and seamless experience when navigating the platform.
                </p>
              </div>
            </Tab>
            <Tab title='AI - Product Matching'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>AI - Product Matching</h1>
              <div>
                <a href={imgAIProductMatching} target='_blank' rel='noreferrer'>
                  <img src={imgAIProductMatching} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered product matching feature leverages a custom-built in-house AI model, combining
                  sentence transformers with a pre-trained Microsoft model to accurately identify relevant product
                  matches. By analyzing product data, our AI efficiently finds similarities across the vast supplier
                  network, helping users navigate the ecosystem with ease.
                </p>
                <br />
                <p>
                  This intelligent matching system enables customers to quickly compare options, discover alternative
                  suppliers, and make informed purchasing decisions—ensuring they always find the right products at the
                  best value.
                </p>
              </div>
            </Tab>
            <Tab title='Machine Learning - Purchase Predictions'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>Machine Learning - Purchase Predictions</h1>
              <div>
                <a href={imgProductPurchasingPredictions} target='_blank' rel='noreferrer'>
                  <img src={imgProductPurchasingPredictions} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered purchasing predictions feature uses a custom in-house machine learning model to
                  forecast a customer's future procurement needs with precision. By analyzing past purchasing history,
                  our model predicts next month’s demand for specific products, helping businesses optimize inventory
                  planning, reduce stockouts, and streamline ordering.
                </p>
                <br />
                <p>
                  Designed for maximum flexibility, this model isn’t limited to MainStem’s platform data alone. It can
                  process any data model identifier, quantity, and date of action—allowing us to ingest data from
                  various sources, including third-party software, APIs, and industry systems like METRC. This
                  adaptability ensures our AI-driven predictions can be applied across diverse operational workflows,
                  enhancing procurement strategies beyond just the MainStem ecosystem.
                </p>
              </div>
            </Tab>
            <Tab title='Data Model Summarization'>
              <h1 style={{ fontSize: 36, fontWeight: 600 }}>Data Model Summarization</h1>
              <div>
                <a href={imgRequisitionSummaries} target='_blank' rel='noreferrer'>
                  <img src={imgRequisitionSummaries} style={{ border: '1px solid #e8e8e8', width: '100%' }} />
                </a>
              </div>
              <br />
              <div>
                <p>
                  MainStem’s AI-powered data summarization feature simplifies complex data models by generating clear,
                  easy-to-understand summaries. Whether it’s a user’s requisition details, order history, or other
                  procurement data, our AI distills large datasets into concise, actionable insights—eliminating the
                  need to sift through overwhelming amounts of information.
                </p>
                <br />
                <p>
                  This summarization capability is applied across multiple data models within our platform, ensuring
                  users can quickly grasp key details, make informed decisions, and navigate their supply chain with
                  confidence and efficiency.
                </p>
              </div>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

export default PageAI
