import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  MagnifyingGlassCircleIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import { InputDateTime, Loader, PageTitle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { APIGoals } from 'api/goals'
import { IAPIGoalsResponse } from 'api/goals/apiResponse'
import { ChartBar, ChartLine, ChartPie } from 'components'
import { classNames } from 'helpers'
import { useEffect, useState } from 'react'

const stats = [
  { id: 1, name: 'Total Users', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase' },
  { id: 2, name: 'Conversion', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
  {
    id: 3,
    name: 'Gross Merchandise Value',
    stat: '$2,432,234',
    icon: CursorArrowRaysIcon,
    change: '3.2%',
    changeType: 'decrease'
  }
]

const PageAppGoals: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)

  const [dateStart, setDateStart] = useState<any>('1/1/2025')
  const [dateEnd, setDateEnd] = useState<any>('12/1/2025')

  const [goals, setGoals] = useState<IAPIGoalsResponse | undefined>(undefined)

  const loadData = () => {
    setLoading(true)

    const apiRequest = {
      dateStart,
      dateEnd
    }

    APIGoals(apiRequest)
      .then((apiResponse) => {
        setGoals(apiResponse)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (dateStart && dateEnd) {
      loadData()
    }
  }, [dateStart, dateEnd])

  useOnFirstLoad(() => {
    loadData()
  })

  return (
    <>
      <PageTitle
        actions={
          <>
            <InputDateTime
              label='Date Start'
              onChange={(newDate) => {
                setDateStart(newDate)
              }}
              value={dateStart}
            />
            <InputDateTime
              label='Date End'
              onChange={(newDate) => {
                setDateEnd(newDate)
              }}
              value={dateEnd}
            />
          </>
        }
        subtitle={`View real-time information about MainStem's Goals and Performance.`}
        title={
          <>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div>
                <MagnifyingGlassCircleIcon className='mr-2 h-8 w-8' />
              </div>
              <div>MainStem Goals</div>
            </div>
          </>
        }
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            {goals?.ordersGMV && (
              <ChartBar color='#440099' currency={true} data={goals.ordersGMV} title='Orders Approved - GMV' />
            )}
            {goals?.ordersTotal && (
              <ChartBar color='#00B5E2' currency={false} data={goals.ordersTotal} title='Orders Approved - Total' />
            )}
            {goals?.gmvByType && (
              <ChartBar
                color='#00B5E2'
                color2='#440099'
                currency={true}
                data={goals.gmvByType.inNetwork}
                data2={goals.gmvByType.outOfNetwork}
                title='In Network vs Out of Network : By Month'
              />
            )}
            {goals?.requisitionsByTypeOverTime && (
              <ChartBar
                color='#00B5E2'
                color2='#440099'
                currency={false}
                data={goals.requisitionsByTypeOverTime.inNetwork}
                data2={goals.requisitionsByTypeOverTime.outOfNetwork}
                title='Requisitions - In Network vs Out of Network : By Month'
              />
            )}
            {goals?.invoicesByTypeOverTime && (
              <ChartBar
                color='#00B5E2'
                color2='#440099'
                currency={false}
                data={goals.invoicesByTypeOverTime.inNetwork}
                data2={goals.invoicesByTypeOverTime.outOfNetwork}
                title='Invoices - In Network vs Out of Network : By Month'
              />
            )}
            {goals?.orderByType && (
              <ChartPie
                //color='#00B5E2'
                currency={true}
                data={goals.orderByType}
                title='Orders Approved - Total'
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export { PageAppGoals }
