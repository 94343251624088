// React
import { Suspense, useState } from 'react'
// Routing
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// Global - Context
import { AppContext } from 'context'
import { IAppContext, IAppContextUser } from 'context/types'
// Global - Helpers
import { Redirect, ScrollToTop } from 'helpers'
// Global - Layouts
import { LayoutApp, LayoutAuth } from 'layout'
// Global - Pages
import {
  PageAuthLogin,
  PageAppAnnouncements,
  PageAppCalendar,
  PageAppGoals,
  PageAppHome,
  PageAppMarket,
  PageAppRoadmap,
  PageAppTeam,
  PageAppTechnology,
  PageAppFiles
} from 'pages'
import PageAI from 'pages/ai'

const AppRoutes = () => {
  const [user, setUser] = useState<IAppContextUser | undefined>(undefined)
  const contextValue = {
    user,
    setUser
  } as IAppContext
  return (
    <AppContext.Provider value={contextValue}>
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path='app' element={<LayoutApp />}>
              <Route path='home' element={<PageAppHome />} index />
              <Route path='announcements' element={<PageAppAnnouncements />} />
              <Route path='roadmap' element={<PageAppRoadmap />} />
              <Route path='goals' element={<PageAppGoals />} />
              <Route path='market' element={<PageAppMarket />} />
              <Route path='calendar' element={<PageAppCalendar />} />
              <Route path='files' element={<PageAppFiles />} />
              <Route path='team' element={<PageAppTeam />} />
              <Route path='technology' element={<PageAppTechnology />} />
              <Route path='ai' element={<PageAI />} />
            </Route>
            <Route path='auth' element={<LayoutAuth />}>
              <Route path='login' element={<PageAuthLogin />} />
            </Route>
            <Route element={<Redirect to='/auth/login' />} path='/' />
          </Routes>
        </Suspense>
        <ScrollToTop />
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default AppRoutes
